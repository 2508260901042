import React, { useEffect, useState } from "react";
import { Button } from "antd";
import styles from "./index.less";
import dayjs from "dayjs";
const Notice = (props) => {
  const [showIndex, setShowIndex] = useState(0);
  const [showItem, setShowItem] = useState();

  const nextShow = () => {
    let index = showIndex + 1;
    setShowIndex(index);
  };

  const ignoredShow = () => {
    props.Ignore();
  };

  useEffect(() => {
    console.log('接收',props.data[showIndex])
    setShowItem(props?.data?.[showIndex]);
  }, [showIndex]);

  useEffect(() => {
    console.log('showItem',showItem)
  }, [showItem])

  return (
    <div className={styles.notice}>
      <p className={styles["notice-content"]}>
        {showItem && (
          <>
            <b>{showItem.event_label }：</b>
            { showItem.event_desc }，告警时间：{ dayjs(showItem.start_ts).format('YYYY-MM-DD HH:mm:ss') }。
          </>
        )}
      </p>
      <div className={styles["notice-bottom"]}>
        <Button className={styles.ignore} type="default" size="small" onClick={ignoredShow}>忽略</Button>
        <Button
          type="info"
          size="small"
          disabled={showIndex === props.data.length - 1}
          onClick={nextShow}
        >下一条
        </Button>
      </div>
    </div>
  );
};

export default Notice;
