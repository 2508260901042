import WujieReact from "wujie-react";
import React, {useState, useEffect, useRef } from "react";
import {Provider, useSelector, useDispatch} from "react-redux";
import {HashRouter as Router, Route, Routes, NavLink, Navigate, useLocation, useNavigate} from "react-router-dom";

import {Dropdown, Space, Select, message, Menu} from "antd";
import {
	DownOutlined,
	UserOutlined,
	LogoutOutlined,
	RollbackOutlined,
	AppstoreOutlined
} from '@ant-design/icons';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import 'antd/dist/antd.min.css'
import 'element-plus/dist/index.css';

import store from "./store";
import switchTheme from "./ThemeCnt";
import {redirectToLogin, redirectToLogout} from "./utils";
import {setTenantId, setUser, setStaId, setStationList, setVppList, setTheme } from "./reducers";

import LoginPage from "./pages/Login";
import PasswordPage from "./pages/password";
import ThemeModal from "./pages/themeModal";
import SettingPage from "./pages/setting";

import OverViewPage from "./pages/newPage/overview"; // 驾驶舱--微网概况
import PvScreenPage from "./pages/newPage/pvscreen"; // 驾驶舱--光储充大屏
import PvScreenWidePage from "./pages/newPage/pvscreenWide"; // 驾驶舱--光储充大屏20:9

import SimpleScreenPage from "./pages/newPage/simpleScreen"; // 驾驶舱--简版大屏
import ElecOverviewPage from "./pages/newPage/elecOverview"; // 配电监测--运行概况
import PowercsptPage from "./pages/newPage/powercspt"; // 配电监测--用电量统计
import PowermgntPage from "./pages/newPage/powermgnt"; // 配电监测--支路用电统计
import TransformerPage from "./pages/newPage/transformer"; // 配电监测--时段用电监测
import TrendPage from "./pages/newPage/trend"; // 配电监测--时段用电对比
import DevicePage from "./pages/newPage/device"; // 配电监测--设备用电对比
import ElecPowerQultPage from "./pages/newPage/elecPowerQult"; // 配电监测--电量质量监测
import ElecEnvPage from "./pages/newPage/elecEnv"; // 配电监测--环境监测
import ElecCompJfpgPage from "./pages/newPage/elecCompJfpg"; // 配电监测--尖峰平谷电量
import ElecTransAnalyPage from "./pages/newPage/elecTransAnaly"; // 配电监测--变压器经济运行
import ElecCompYoyPage from "./pages/newPage/elecCompYoy"; // 配电监测--同环比分析
import ElecFgpReportPage from "./pages/newPage/elecfgpReport"; // 配电监测--尖峰平谷报表
import ElecTraiffPage from "./pages/newPage/elecTraiff"; // 配电监测--基本电费分析
import ElecFeeCosPage from "./pages/newPage/elecFeeCos"; // 配电监测--力调电费分析
import ElecMeterReportPage from "./pages/newPage/elecMeterReport"; // 配电监测--远程抄表
import ElecLoadReportPage from "./pages/newPage/elecLoadReport"; // 配电监测--负荷极值报表
import ElecDocReportPage from "./pages/newPage/elecDocReport"; // 配电监测--用电分析报告

import OptOverviewPage from "./pages/newPage/optOverview"; // 运维管理--看板
import OptTourPage from "./pages/newPage/optTour"; // 运维管理--巡视工单
import OptRepairPage from "./pages/newPage/optRepair"; // 运维管理--抢修工单
import OptDefectPage from "./pages/newPage/optDefect"; // 运维管理--缺陷工单
import OptMaintenancePage from "./pages/newPage/optMaintenance"; // 运维管理--维保工单
import OptProjectPage from "./pages/newPage/optProject"; // 运维管理--巡视项目配置
import OptTemplatePage from "./pages/newPage/optTemplate"; // 运维管理--巡视模板配置
import OptPlanPage from "./pages/newPage/optPlan"; // 运维管理--巡视计划
import OptGroupPage from "./pages/newPage/optGroup"; // 运维管理--运维班组

import EneOverviewPage from "./pages/newPage/eneOverview"; // 能耗碳排--看板
import EneBranchPage from "./pages/newPage/eneBranch"; // 能耗碳排--支路能耗统计
import EneGroupPage from "./pages/newPage/eneGroup"; // 能耗碳排--分组能耗统计
import EneBackStepPage from "./pages/newPage/eneBackStep"; // 能耗碳排--能耗折标统计
import EneYoYPage from "./pages/newPage/eneYoY"; // 能耗碳排--能耗同比分析
import EneQoQPage from "./pages/newPage/eneQoQ"; // 能耗碳排--能耗环比分析
import EneFlowPage from "./pages/newPage/eneFlow"; // 能耗碳排--能耗流向图
import EneSettingPage from "./pages/newPage/eneSetting"; // 能耗碳排--能耗分类分项配置

import PvOverviewPage from "./pages/newPage/pvOverview"; // 光伏监测--运行概况
import PowerMonitorPage from "./pages/newPage/powerMonitor"; // 光伏监测--发用电量监测
import InverterMonitorPage from "./pages/newPage/inverter"; // 光伏监测--变压器监测
import ContrastAnalysisPage from "./pages/newPage/contrast"; // 光伏监测--变压器对比
import EnergyOverviewPage from "./pages/newPage/energyOverview"; // 储能监测--运行概况
import EnergyDevicePage from "./pages/newPage/energyDevice"; // 储能监测--bcs/bms
import EnergyQualityPage from "./pages/newPage/energyQuality"; // 储能监测--充放电量
import ChargeOverviewPage from "./pages/newPage/chargeOverview"; // 停充监测--运行概况
import ChargeDataPage from "./pages/newPage/chargeData"; // 停充监测--设备充电数据监控
import ChargeElecPage from "./pages/newPage/chargeElec"; // 停充监测--设备充电量监测
import ChargeStationPage from "./pages/newPage/chargeStation"; // 停充监测--设备充电量监测
import StagyConfigurePage from "./pages/newPage/stagyConf"; // 策略配置--配置
import StagyListPage from "./pages/newPage/stagyList"; // 策略配置--列表
import StagyLogPage from "./pages/newPage/stagyLog"; // 策略配置--日志
import StagyControlPage from "./pages/newPage/stagyControl"; // 策略配置--手动控制

import VppOverViewPage from "./pages/newPage/vppOverview";
import VppManagerPage from "./pages/newPage/vppManager";
import VppInvitePage from "./pages/newPage/vppInvite";
import VppReplyPage from "./pages/newPage/vppReply";
import VppAdjustPage from "./pages/newPage/vppAdjust";
import VppUserLogPage from "./pages/newPage/vppUserLog";
import VppMonthBillPage from "./pages/newPage/vppMonthBill";
import VppClientBillPage from "./pages/newPage/vppClientBill";
import VppClientEvaluatePage from "./pages/newPage/vppClientEvaluate";
import VppClientInvitePage from "./pages/newPage/vppClientInvite";
import VppBasePage from "./pages/newPage/vppBase";
import VppScreen from "./pages/newPage/vppScreen";

import ChargeReportPage from "./pages/newPage/chargeReport";
import ChargeAnalysisPage from "./pages/newPage/chargeAnalysis";

import AlarmPage from "./pages/newPage/Alarm";
import FullScreenPage from "./pages/newPage/FullScreen";
import PvScreenNewPage from "./pages/newPage/pvScreenNew";
import IncomePage from "./pages/newPage/Income";
import IncomeReportPage from "./pages/newPage/IncomeReport";
import InverterReportPage from "./pages/newPage/InverterReport";
import ListViewPage from "./pages/newPage/ListView";
import PhotovoltaicMonitorPage from "./pages/newPage/PhotovoltaicMonitor";
import PowerContrastPage from "./pages/newPage/PowerContrast";
import ComparativePage from "./pages/newPage/Comparative";
import RealStatusPage from "./pages/newPage/RealStatus";
import StationReportPage from "./pages/newPage/StationReport";
import StatisticsViewPage from "./pages/newPage/StatisticsView";

import WdwScreenPage from "./pages/newPage/wdwscreen"; // 微电网大屏
import WdwScreenWidePage from "./pages/newPage/wdwscreenWide"; // 微电网大屏
import WdwNewScreenPage from "./pages/newPage/wdwNewScreen"; // 微电网大屏v2

import ChargeScreenPage from "./pages/newPage/chargeScreen"; // 充电桩平台--大屏
import ChargeCardListPage from "./pages/newPage/chargeCardList"; // 充电桩平台--卡片管理
import ChargeClientPage from "./pages/newPage/chargeClient"; // 充电桩平台--大客户管理
import ChargeCarPage from "./pages/newPage/chargeCar"; // 充电桩平台--车辆管理
import ChargeRealNamePage from "./pages/newPage/chargeRealName"; // 充电桩平台--实名认证
import ChargeParkPage from "./pages/newPage/chargePark"; // 充电桩平台--车场管理
import ChargeCouponPage from "./pages/newPage/chargeCoupon"; // 充电桩平台--优惠券管理
import ChargeSpAccountPage from "./pages/newPage/chargeSpAccount"; // 充电桩平台--分账商户管理
import ChargeWidthdrawPage from "./pages/newPage/chargeWidthdraw"; // 充电桩平台--提现记录
import ChargeSpOrderPage from "./pages/newPage/chargeSpOrder"; // 充电桩平台--分账订单

import ChargePileMfPage from "./pages/newPage/chargePileMf"; // 充电桩平台--电桩厂商
import ChargePileCodePage from "./pages/newPage/chargePileCode"; // 充电桩平台--桩号管理
import ChargeLightBoxPage from "./pages/newPage/chargeLightBox"; // 充电桩平台--灯箱管理
import ChargePlatformPage from "./pages/newPage/chargePlatform"; // 充电桩平台--平台管理
import ChargeRechargePage from "./pages/newPage/chargeRecharge"; // 充电桩平台--充值订单管理
import ChargeVipBillPage from "./pages/newPage/chargeVipBill"; // 充电桩平台--会员电价管理
import ChargeNewsPage from "./pages/newPage/chargeNews"; // 充电桩平台--资讯管理
import ChargeVipOrderPage from "./pages/newPage/chargeVipOrder"; // 充电桩平台--会员订单管理
import ChargeOrderPage from "./pages/newPage/chargeOrder"; // 充电桩平台--充电订单管理
import ChargeStationMgrPage from "./pages/newPage/chargeStationMgr"; // 充电桩平台--充电站管理
import ChargePileMgrPage from "./pages/newPage/chargePileMgr"; // 充电桩平台--充电桩管理
import ChargeIotPage from "./pages/newPage/chargeIot"; // 充电桩平台--4G卡管理
import ChargeSortPage from "./pages/newPage/chargeSort"; // 充电桩平台--电站分类管理
import ChargeVipMgrPage from "./pages/newPage/chargeVipMgr"; // 充电桩平台--零充会员管理
import ChargeVipRulePage from "./pages/newPage/chargeVipRule"; // 充电桩平台--零充会员规则
import ChargeVipIconPage from "./pages/newPage/chargeVipIcon"; // 充电桩平台--会员权益图标
import ChargeVipRightsPage from "./pages/newPage/chargeVipRights"; // 充电桩平台--会员权益
import ChargeCouponListPage from "./pages/newPage/chargeCouponList"; // 充电桩平台--卡券管理
import ChargeCouponActivePage from "./pages/newPage/chargeCouponActive"; // 充电桩平台--卡券活动管理
import ChargeSwiperPage from "./pages/newPage/chargeSwiper"; // 充电桩平台--轮播图管理
import ChargeCityPage from "./pages/newPage/chargeCity"; // 充电桩平台--城市管理
import ChargeEvaMgrPage from "./pages/newPage/chargeEvaMgr"; //充电桩--评价管理
import ChargeFeedbackMgrPage from "./pages/newPage/chargeFeedbackMgr"; //充电桩--问题反馈管理
import ChargeCusProMgrPage from "./pages/newPage/chargeCusProMgr"; //充电桩--充电枪说明
import ChargePlaceMgrPage from "./pages/newPage/chargePlaceMgr"; //充电桩--占位催挪管理
import ChargeParkFeeMgrPage from "./pages/newPage/chargeParkFeeMgr"; // 充电桩--停车费申诉
import ChargeGunInfoMgrPage from "./pages/newPage/chargeGunInfoMgr"; // 充电桩--充电枪说明
import ChargeOperMgrPage from "./pages/newPage/chargeOptMgr"; // 充电桩平台--运营商管理
import ChargeSysParamsMgrPage from "./pages/newPage/chargeSysParamsMgr"; //充电桩--系统参数
import ChargeCarUseMgrPage from "./pages/newPage/chargeCarUseMgr"; //充电桩--车辆用途管理
import ChargeCarFriendMgrPage from "./pages/newPage/chargeCarFriendMgr"; //充电桩--车友印象
import ChargeRightParamsMgrPage from "./pages/newPage/chargeRightParamsMgr"; //充电桩--权益参数
import ChargePriceMgrPage from "./pages/newPage/chargePriceMgr"; //充电桩--电价策略管理
import ChargeStationPlanMgrPage from "./pages/newPage/chargeStationPlanMgr"; //充电桩--充电站维护计划
import ChargeClockinMgrPage from "./pages/newPage/chargeClockinMgr"; //充电桩--考勤总览
import ChargeOptUserMgrPage from "./pages/newPage/chargeOptUserMgr"; //充电桩--运营人员管理
import ChargeTourPlanMgrPage from "./pages/newPage/chargeTourPlanMgr"; //充电桩--巡更计划
import ChargeRecordMgrPage from "./pages/newPage/chargeRecordMgr"; //充电桩--报表管理
import ChargeFinanceMgrPage from "./pages/newPage/chargeFinanceMgr"; //充电桩--财务开票
import AlarmInfoPage from "./pages/newPage/AlarmInfo"; //告警信息
import VideoMornitorPage from "./pages/newPage/videomornitor";

import logoImg from "./assets/logo_sw.png";
import ajax from './api';
import AlarmSocket from './components/websockets';

const {bus} = WujieReact;
function AppComp() {
	const handle = useFullScreenHandle();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isFull, setIsFull] = useState(false);
	const [active, setActive] = useState(false);
	const [show, setShow] = useState(false);
	const [userInfo, setUserInfo] = useState();
	// const [logoUrl, setLogoUrl] = useState(logoImg);
	const [logoUrl, setLogoUrl] = useState();
	const {user, stationList, tenantId } = useSelector(state => state.user);
	const [stationId, setStationId] = useState();
	const [topMenuList, setTopMenuList] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [subMenus, setSubMenus] = useState([]);
	const [subSelectedKeys, setSubSelectedKeys] = useState([]);
	const [vppId, setVppId] = useState();
	const [deviceId, setDeviceId] = useState();
	const [themeShow, setThemeShow] = useState(false);

	const searchParams = new URLSearchParams(window.location.search);
	const token = searchParams.get('token');

	const items = [
		{
			key: '1',
			label: (
				<div onClick={() => { setShow(!show) }}>
					<UserOutlined style={{marginRight: 8}}/>
					账户设置
				</div>
			)
		},
		{
			key: '3',
			label: (
				<div onClick={() => { setThemeShow(true) }}>
					<AppstoreOutlined style={{marginRight: 8}}/>
					主题设置
				</div>
			)
		},
		{
			key: '2',
			label: (
				<div onClick={() => logout()}>
					<LogoutOutlined style={{marginRight: 8}}/>
					退出登录
				</div>
			)
		},
	];

	const setFavicon = (faviconHref) => {
		if(!faviconHref) { return }
		let favicon = document.querySelector('link[rel="icon"]');
		if (favicon !== null) {
			favicon.href = faviconHref
		} else {
			favicon = document.createElement('link')
			favicon.rel = 'icon'
			favicon.href = faviconHref
			document.head.appendChild(favicon)
		}
	}

	useEffect(() => {
		if (user && user.userId) {
			setUserInfo(user);
			setLogoUrl((user.currentTenant && user.currentTenant.fullLogo) ? user.currentTenant.fullLogo : logoImg)
			if (user.currentTenant && user.currentTenant.name) {
				document.title = user.currentTenant.name
				setFavicon(user.currentTenant.favicon);
			}
		}
	}, [user])

	const logout = () => {
		localStorage.setItem('saas-user', null);
		localStorage.removeItem('tenantId');
		localStorage.removeItem('saas-token');
		localStorage.removeItem('isClient');
		localStorage.removeItem('saas-deviceId');
		localStorage.removeItem('saas-menu');
		localStorage.removeItem('saas-staId');
		dispatch(setTenantId(''));
		dispatch(setStaId(''));
		dispatch(setUser({}));
		window.globalStationId = '';
		window.globalStationName = '';
		window.globalStationBackImg = '';

		redirectToLogout();
	}

	const changeActive = (val) => {
		setActive(val);
	}

	const handleScreen = (type) => {
		if (type == 1) {
			setIsFull(true)
			handle.enter();
		} else {
			setIsFull(false);
			handle.exit();
		}
	}

	const changeTenant = (val) => {
		bus.$emit('change-tenant', val);
		dispatch(setTenantId(val));
		dispatch(setUser({}));
		localStorage.setItem('tenantId', val);
		localStorage.setItem('saas-user', '');
		localStorage.removeItem('saas-deviceId');
		handleGetUserInfo();
		handleGetDevices();
	}

 	const handleGetUserInfo = async (type) => {
		let json = {
			incloudPermission: false,
			incloudRouter: true,
			app: 'wdw'
		}
		if (localStorage.getItem('isClient') == 'true') {
			json.autoVpp = false;
		} else {
			json.autoVpp = true;
		}
		const res = await ajax.GetUserInfo({ ...json });
		if (res.data.code === 200) {
			dispatch(setUser(res.data.data));
			localStorage.setItem('saas-token', token);
			localStorage.setItem('saas-user', JSON.stringify(res.data.data));
      console.log('加载完成……')
      AlarmSocket.init(res.data.data.userId, res.data.data.tenantId);
      AlarmSocket.createAudio();
		} else if (res.data.code === 403) {
			message.error(res.data.message);
			setTimeout(() => {
				redirectToLogin()
			}, 2000);
		} else if (res.status == 401) {
			message.error('请重新登录');
			setTimeout(() => {
				redirectToLogin()
			}, 2000);
		} else {
			message.error(res.data.message);
		}
	}

	const handleGetDevices = async () => {
		const res = await ajax.GetUserDevices();
		if (res.data.successful) {
			if (res.data.data && res.data.data.length) {
				dispatch(setStationList(res.data.data));
				let station = {}
				res.data.data.forEach(item => {
					if (localStorage.getItem('saas-deviceId') && item.id === localStorage.getItem('saas-deviceId')) {
						station = item
					}
				})
				dispatch(setStaId(station.identify || res.data.data[0].identify));
				setStationId(station.identify || res.data.data[0].identify);

				localStorage.setItem('saas-staId', station.identify || res.data.data[0].identify);
				localStorage.setItem('saas-deviceId', station.id || res.data.data[0].id);
				window.globalStationId = station.identify || res.data.data[0].identify;
				window.globalStationName = station.label || res.data.data[0].label;
			} else {
				window.globalStationId = '';
				window.globalStationName = '';
				window.globalStationBackImg = '';
			}
		} else {
			window.globalStationId = '';
			window.globalStationName = '';
			window.globalStationBackImg = '';
		}
	}

	const handleGetVppList = async () => {
		const res = await ajax.GetVppList({});
		if (res.data.successful) {
			if (res.data.data.length > 0) {
				setVppId(res.data.data[0].id);
				dispatch(setVppList(res.data.data));
				window.globalVppId = res.data.data[0].id;
			}
		}
	}

	const changeStation = (value) => {
		localStorage.setItem('saas-staId', value);
		dispatch(setStaId(value));
		setStationId(value);
		window.globalStationId = value;
		stationList.forEach(item => {
			if (item.identify === value) {
				localStorage.setItem('saas-deviceId', item.id);
				window.globalStationName = item.label;
				window.globalStationBackImg = item.image;
			}
		})
	}

	useEffect(() => {
		if (!token) {
			redirectToLogin();
		} else {
			handleGetUserInfo();
		}
	}, [token])

	useEffect(() => {
		if (stationList.length == 0) {
			handleGetDevices();
		}
	}, [stationList]);

	useEffect(() => {
		if (userInfo && userInfo.routerTree) {
			const list = [];
			userInfo.routerTree.forEach((item, ind) => {
				let json = {
					label: item.label,
					key: item.component,
					subs: []
				}
				if (item.children) {
					const subs = [];
					item.children.forEach((child, cid) => {
						subs.push({
							label: child.label,
							key: child.component,
						})
						if (child.children) {
							const children = [];
							child.children.forEach((child2, cid2) => {
								children.push({
									label: child2.label,
									key: child2.component,
								})
							})
							subs[cid].children = [...children];
						}
					})
					json.subs = [...subs]
				}
				if (ind == 0) {
					setSelectedKeys([item.component]);
				}
				list.push(json);
			})
			setTopMenuList([...list]); // 正式环境或测试环境
		}
	}, [userInfo]);

	const handleMenuChange = ({item, key}) => {
		if (key == '/setting') {
			navigate(key);
		}
		// console.log(key, 'key')
		// if (key == 'le5le') {
		// 	// console.log(window.location, 'location')
		// 	// const token = window.location.search.split('?token=')[1]
		// 	// const url = `http://localhost:88/token=${token}`;
		// 	// console.log(url, 'url')
		// 	// window.open(url);
		// }
		setSelectedKeys([key]);
	}

	const handleSubMenuChange = ({item, key, keyPath}) => {
		localStorage.setItem('saas-menu', key)
		setSubSelectedKeys([key]);
	}

	const handleBack = () => {
		dispatch(setStationList([]));
		dispatch(setTenantId(''));
		setStationId()
		localStorage.setItem('isClient', false)
		localStorage.removeItem('tenantId');
		handleGetUserInfo();
	}

	const refreshTheme = (value) => {
		setThemeShow(false);
		if (value) {
			dispatch(setTheme(value));
			switchTheme(value);
			bus.$emit('refresh-theme', value);
		}
	}

	useEffect(() => {
		if (selectedKeys.length) {
			if (selectedKeys[0] == '/setting') {
				setSubMenus([]);
				setSubSelectedKeys([]);
				localStorage.removeItem('saas-menu');

			} else {
				let newList = [];
				topMenuList.forEach(item => {
					if (item.key == selectedKeys[0]) {
						newList = [...item.subs];
						item.subs.forEach((child, ind) => {
							// if (ind == 0) setSubSelectedKeys([child.key]);
							if (localStorage.getItem('isClient') == 'false') {
								if (child.key == 'VppManger') {
									setSubSelectedKeys([child.key]);
									localStorage.setItem('saas-menu', child.key);
								}
							} else {
								if (ind == 0) {
									setSubSelectedKeys([child.key]);
									localStorage.setItem('saas-menu', child.key);
								}
							}
						})
					}
				})
				// setSubMenus([...newList])
				setSubMenus([...newList])
			}
		}
	}, [selectedKeys])

	useEffect(() => {
		if (userInfo && userInfo.isVpp && vppId) {
			if (subSelectedKeys.length) {
				navigate('/');
				setTimeout(() => {
					navigate(subSelectedKeys[0].indexOf('/') == -1 ? `/${subSelectedKeys[0]}` : `${subSelectedKeys[0]}`)
				}, [500])
			}
		} else {
			if (subSelectedKeys.length > 0) {
				if (stationId) {
					navigate('/');
					setTimeout(() => {
						navigate(subSelectedKeys[0].indexOf('/') == -1 ? `/${subSelectedKeys[0]}?${stationId}` : `${subSelectedKeys[0]}?${stationId}`)
					}, [500])
				} else {
					navigate('/');
					setTimeout(() => {
						navigate(subSelectedKeys[0].indexOf('/') == -1 ? `/${subSelectedKeys[0]}` : `${subSelectedKeys[0]}`)
					}, [500])
				}
			}
		}
	}, [userInfo, subSelectedKeys, stationId, vppId])

	useEffect(() => {
		switchTheme(localStorage.getItem('theme') || 'dark');
		bus.$on("testview", (value1, value2) => {
			localStorage.setItem('isClient', true);
			localStorage.setItem('tenantId', value1);
			localStorage.setItem('saas-deviceId', value2)
			setDeviceId(value2);
			dispatch(setVppList([]));
			dispatch(setStationList([]));
			dispatch(setStaId(''));
			dispatch(setTenantId(value1));
			setVppId();
			setStationId();
			handleGetUserInfo();
		})
		return () => {
			bus.$off("testview");
		}
	}, [])

	return (
		<FullScreen handle={handle}>
			<div className={active ? "nav active" : "nav"} style={{ display: 'flex'}}>
				<img src={logoUrl} height="56%"/>
				<nav style={{ flex: 1}}>
					{
						topMenuList.length && (
							<Menu items={topMenuList} mode="horizontal" selectedKeys={selectedKeys} onSelect={handleMenuChange}/>
						)
					}
				</nav>
				<div className="user_wrap">
					{
						localStorage.getItem('isClient') == 'true' && (
							<div style={{color: '#fff', marginRight: 12 }} className="full_wrap" onClick={() => handleBack()}>
								<RollbackOutlined />
								返回虚拟电厂
							</div>
						)
					}
					{
						userInfo && (localStorage.getItem('isClient') == 'true' || (userInfo && !userInfo.isVpp)) && userInfo.childTenants && userInfo.childTenants.length > 0 && (
							<>
								<Select
									style={{width: 140, margin: 'auto 16px'}}
									placeholder="全部租户"
									onChange={changeTenant}
									showSearch
									value={tenantId || localStorage.getItem('tenantId') || '0'}
									disabled={localStorage.getItem('isClient') == 'true'}
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
								>
									<Select.Option key={0} value="0" label="全部租户">全部租户</Select.Option>
									{userInfo.childTenants.map((item, index) => {
										return (
											<Select.Option key={item.id} value={item.id} label={item.name}>{item.name}</Select.Option>
										)
									})}
								</Select>
							</>
						)
					}
					{/* {
						stationList.length > 0 && (localStorage.getItem('isClient') == 'true' || (userInfo && !userInfo.isVpp)) && (
							<Select
								style={{width: 140, margin: 'auto 16px'}}
								placeholder="选择站点"
								value={stationId}
								onChange={changeStation}
								showSearch
								disabled={localStorage.getItem('isClient') == 'true'}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
							>
								{stationList.map((item, index) => {
									return (
										<Select.Option key={item.identify} value={item.identify} label={item.label}>
											{item.label}
										</Select.Option>
									)
								})}
							</Select>
						)
					} */}
					{/* {
						vppList.length > 0 && localStorage.getItem('isClient') !== 'true' && (
							<Select
								style={{width: 200, margin: 'auto 16px'}}
								placeholder="选择虚拟电厂"
								value={vppId}
								onChange={changeVpp}
								showSearch
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
							>
								{vppList.map((item, index) => {
									return (
										<Select.Option key={item.id} value={item.id} label={item.name}>
											{item.name}
										</Select.Option>
									)
								})}
							</Select>
						)
					} */}

					{/* {isFull ? (
						<div style={{color: '#fff'}} className="full_wrap" onClick={() => handleScreen(0)}>
							<FullscreenExitOutlined/>
							退出全屏
						</div>
					) : (
						<div style={{color: '#fff'}} className="full_wrap" onClick={() => handleScreen(1)}>
							<FullscreenOutlined/>
							开启全屏
						</div>
					)} */}

					<Dropdown menu={{items}}>
						<div style={{color: '#fff', lineHeight: '60px', fontSize: 16}}>
							<UserOutlined style={{marginRight: 6, fontSize: 16}}/>
							{userInfo ? userInfo.realName : '--'}&nbsp;
							<DownOutlined/>
						</div>
					</Dropdown>
				</div>
			</div>
			<div className="cnt_wrap">
				{
					subMenus.length > 0 && (
						<div className="side-bar">
							<Menu
								items={subMenus}
								mode="inline"
								theme="dark"
								selectedKeys={subSelectedKeys}
								onSelect={handleSubMenuChange}
							/>
							<div className="footer-title">
								{(localStorage.getItem('isClient') == 'true' || (userInfo && !userInfo.isVpp)) ? '智能微电网平台' : '虚拟电厂平台'}
							</div>
						</div>
					)
				}
				<div
					className="content"
					onClick={() => changeActive(false)}
					style={{width: subMenus.length > 0 ? 'calc(100% - 200px)' : '100%'}}
				>
					<Routes>
						<Route exact path="/" element={<LoginPage />}/>
						<Route exact path="/OverView/" element={<OverViewPage />} />
						<Route exact path="/ScreenGCC/" element={<PvScreenPage />} />
						<Route exact path="/ScreenGCCWide/" element={<PvScreenWidePage />} />
						<Route exact path="/HomeSimple/" element={<SimpleScreenPage />} />
						<Route exact path="/ElectricityOverview/" element={<ElecOverviewPage />} />
						<Route exact path="/Powercspt/" element={<PowercsptPage />} />
						<Route exact path="/Powermgnt/" element={<PowermgntPage />} />
						<Route exact path="/Transformer/" element={<TransformerPage />} />
						<Route exact path="/Trend/" element={<TrendPage />} />
						<Route exact path="/Device/" element={<DevicePage />} />
						<Route exact path="/PVOverview/" element={<PvOverviewPage />} />
						<Route exact path="/PowerMonitor/" element={<PowerMonitorPage />} />
						<Route exact path="/InverterMonitor/" element={<InverterMonitorPage />} />
						<Route exact path="/ContrastAnalysis/" element={<ContrastAnalysisPage />} />
						<Route exact path="/EnergyOverview/" element={<EnergyOverviewPage />} />
						<Route exact path="/DeviceMonitor/" element={<EnergyDevicePage />} />
						<Route exact path="/QuantityMonitor/" element={<EnergyQualityPage />} />
						<Route exact path="/ChargingOverview/" element={<ChargeOverviewPage />} />
						<Route exact path="/ChargeData/" element={<ChargeDataPage />} />
						<Route exact path="/ChargeCapacity/" element={<ChargeElecPage />} />
						<Route exact path="/ChargeStation/" element={<ChargeStationPage />} />
						<Route exact path="/StrategyConfigure/" element={<StagyConfigurePage />} />
						<Route exact path="/StrategyTable/" element={<StagyListPage />} />
						<Route exact path="/StrategyLog/" element={<StagyLogPage />} />
						<Route exact path="/ManualControl/" element={<StagyControlPage />} />
						<Route exact path="/ChargeReport/" element={<ChargeReportPage />} />
						<Route exact path="/ChargeAnalysis/" element={<ChargeAnalysisPage />} />
						<Route exact path="/Powerqult/" element={<ElecPowerQultPage />} />
						<Route exact path="/Enviroment/" element={<ElecEnvPage />} />
						<Route exact path="/Compyoy/" element={<ElecCompYoyPage />} />
						<Route exact path="/Reportdoc/" element={<ElecDocReportPage />} />
						<Route exact path="/ReportFgp/" element={<ElecFgpReportPage />} />
						<Route exact path="/ReportMeter/" element={<ElecMeterReportPage />} />
						<Route exact path="/ReportLoad/" element={<ElecLoadReportPage />} />
						<Route exact path="/FeeCos/" element={<ElecFeeCosPage />} />
						<Route exact path="/Traiff/" element={<ElecTraiffPage />} />
						<Route exact path="/TransfAnaly/" element={<ElecTransAnalyPage />} />

						{/* 能耗管理 */}
						<Route exact path="/EneOverview/" element={<EneOverviewPage />} />
						<Route exact path="/EneBranch/" element={<EneBranchPage />} />
						<Route exact path="/EneGroup/" element={<EneGroupPage />} />
						<Route exact path="/EneBackStep/" element={<EneBackStepPage />} />
						<Route exact path="/EneYoY/" element={<EneYoYPage />} />
						<Route exact path="/EneQoQ/" element={<EneQoQPage />} />
						<Route exact path="/EneFlow/" element={<EneFlowPage />} />
						<Route exact path="/EneSetting/" element={<EneSettingPage />} />

						{/* 运维管理 */}
						<Route exact path="/OptOverview/" element={<OptOverviewPage />} />
						<Route exact path="/OptTour/" element={<OptTourPage />} />
						<Route exact path="/OptRepair/" element={<OptRepairPage />} />
						<Route exact path="/OptDefect/" element={<OptDefectPage />} />
						<Route exact path="/OptMaintenance/" element={<OptMaintenancePage />} />
						<Route exact path="/OptProject/" element={<OptProjectPage />} />
						<Route exact path="/OptTemplate/" element={<OptTemplatePage />} />
						<Route exact path="/OptPlan/" element={<OptPlanPage />} />
						<Route exact path="/OptGroup/" element={<OptGroupPage />} />

						{/* 虚拟电厂 */}
						<Route exact path="/VppOverview/" element={<VppOverViewPage />} />
						<Route exact path="/VppScreen/" element={<VppScreen />} />
						<Route exact path="/VppManger/" element={<VppManagerPage />} />
						<Route exact path="/VppAdjust/" element={<VppAdjustPage />} />
						<Route exact path="/VppInviteMgr/" element={<VppInvitePage />} />
						<Route exact path="/VppReplyMgr/" element={<VppReplyPage />} />
						<Route exact path="/VppUserLog/" element={<VppUserLogPage />} />
						<Route exact path="/VppMonthBill/" element={<VppMonthBillPage />} />
						<Route exact path="/VppClientBill/" element={<VppClientBillPage />} />
						<Route exact path="/VppClientEvaluate/" element={<VppClientEvaluatePage />} />
						<Route exact path="/ClientVppInvite/" element={<VppClientInvitePage />} />
						<Route exact path="/ClientVppBase/" element={<VppBasePage />} />
						{/* 微电网大屏 */}
						<Route exact path="/ScreenWdw/" element={<WdwScreenPage />} />
						<Route exact path="/ScreenWdwWide/" element={<WdwScreenWidePage />} />
						<Route exact path="/WdwNewScreen/" element={<WdwNewScreenPage />} />
						{/* 光伏 */}
            <Route exact path="/Alarm/" element={<AlarmPage />} />
            <Route exact path="/FullScreen/" element={<FullScreenPage />} />
            <Route exact path="/PvScreen/" element={<PvScreenNewPage />} />
            <Route exact path="/Income/" element={<IncomePage />} />
            <Route exact path="/IncomeReport/" element={<IncomeReportPage />} />
            <Route exact path="/InverterReport/" element={<InverterReportPage />} />
            <Route exact path="/ListView/" element={<ListViewPage />} />
            <Route exact path="/PhotovoltaicMonitor/" element={<PhotovoltaicMonitorPage />} />
            <Route exact path="/PowerContrast/" element={<PowerContrastPage />} />
            <Route exact path="/Comparative/" element={<ComparativePage />} />
            <Route exact path="/RealStatus/" element={<RealStatusPage />} />
            <Route exact path="/StationReport/" element={<StationReportPage />} />
            <Route exact path="/StatisticsView/" element={<StatisticsViewPage />} />

						{/* 充电桩平台 */}
            <Route exact path="/ChargeScreen/" element={<ChargeScreenPage />} />
						<Route exact path="/ChargeCardList/" element={<ChargeCardListPage />} />
						<Route exact path="/ChargeClient/" element={<ChargeClientPage />} />
						<Route exact path="/ChargeCar/" element={<ChargeCarPage />} />
						<Route exact path="/ChargeRealName/" element={<ChargeRealNamePage />} />
						<Route exact path="/ChargeParkList/" element={<ChargeParkPage />} />
						<Route exact path="/ChargeCoupon/" element={<ChargeCouponPage />} />
						<Route exact path="/ChargeSpAccount/" element={<ChargeSpAccountPage />} />
						<Route exact path="/ChargeWidthdraw/" element={<ChargeWidthdrawPage />} />
						<Route exact path="/ChargeSpOrder/" element={<ChargeSpOrderPage />} />

						<Route exact path="/ChargePileMf/" element={<ChargePileMfPage />} />
						<Route exact path="/ChargePileCode/" element={<ChargePileCodePage />} />
						<Route exact path="/ChargeLightBox/" element={<ChargeLightBoxPage />} />
						<Route exact path="/ChargePlatform/" element={<ChargePlatformPage />} />
						<Route exact path="/ChargeRecharge/" element={<ChargeRechargePage />} />
						<Route exact path="/ChargeVipBill/" element={<ChargeVipBillPage />} />
						<Route exact path="/ChargeNews/" element={<ChargeNewsPage />} />
						<Route exact path="/ChargeVipOrder/" element={<ChargeVipOrderPage />} />
						<Route exact path="/ChargeOrder/" element={<ChargeOrderPage />} />
						<Route exact path="/ChargeStationMgr/" element={<ChargeStationMgrPage />} />
						<Route exact path="/ChargePileMgr/" element={<ChargePileMgrPage />} />
						<Route exact path="/ChargeIot/" element={<ChargeIotPage />} />
						<Route exact path="/ChargeSort/" element={<ChargeSortPage />} />
						<Route exact path="/ChargeVipMgr/" element={<ChargeVipMgrPage />} />
						<Route exact path="/ChargeVipRule/" element={<ChargeVipRulePage />} />
						<Route exact path="/ChargeVipIcon/" element={<ChargeVipIconPage />} />
						<Route exact path="/ChargeVipRights/" element={<ChargeVipRightsPage />} />
						<Route exact path="/ChargeCouponList/" element={<ChargeCouponListPage />} />
						<Route exact path="/ChargeCouponActive/" element={<ChargeCouponActivePage />} />
						<Route exact path="/ChargeSwiper/" element={<ChargeSwiperPage />} />
						<Route exact path="/ChargeCity/" element={<ChargeCityPage />} />
						<Route exact path="/ChargeEvaMgr/" element={<ChargeEvaMgrPage />} />
						<Route exact path="/ChargeFeedbackMgr/" element={<ChargeFeedbackMgrPage />} />
						<Route exact path="/ChargeCusProMgr/" element={<ChargeCusProMgrPage />} />
						<Route exact path="/ChargePlaceMgr/" element={<ChargePlaceMgrPage />} />
						<Route exact path="/ChargeParkFeeMgr/" element={<ChargeParkFeeMgrPage />} />
						<Route exact path="/ChargeGunInfoMgr/" element={<ChargeGunInfoMgrPage />} />
						<Route exact path="/ChargeOperMgr/" element={<ChargeOperMgrPage />} />
						<Route exact path="/ChargeSysParamsMgr/" element={<ChargeSysParamsMgrPage />} />
						<Route exact path="/ChargeCarUseMgr/" element={<ChargeCarUseMgrPage />} />
						<Route exact path="/ChargeCarFriendMgr/" element={<ChargeCarFriendMgrPage />} />
						<Route exact path="/ChargeRightParamsMgr/" element={<ChargeRightParamsMgrPage />} />
						<Route exact path="/ChargePriceMgr/" element={<ChargePriceMgrPage />} />
						<Route exact path="/ChargeStationPlanMgr/" element={<ChargeStationPlanMgrPage />} />
						<Route exact path="/ChargeClockinMgr/" element={<ChargeClockinMgrPage />} />
						<Route exact path="/ChargeOptUserMgr/" element={<ChargeOptUserMgrPage />} />
						<Route exact path="/ChargeTourPlanMgr/" element={<ChargeTourPlanMgrPage />} />
						<Route exact path="/ChargeRecordMgr/" element={<ChargeRecordMgrPage />} />
						<Route exact path="/ChargeFinanceMgr/" element={<ChargeFinanceMgrPage />} />
						<Route exact path="/AlarmInfo/" element={<AlarmInfoPage />} />
						<Route exact path="/VideoMornitor/" element={<VideoMornitorPage />} />

						{/* <Route exact path="/ChargeSpAccount/" element={<ChargeSpAccountPage />} /> */}
						{/* <Route exact path="/basic" element={<BasicPage />} /> */}
						{/* <Route exact path="/electric" element={<ElectricPage />} /> */}
						{/* <Route exact path="/photov" element={<PhotovPage />} /> */}
						{/* <Route exact path="/storeEnergy" element={<StoreEnergyPage />} /> */}
						{/* <Route exact path="/chargep" element={<ChargepPage />} /> */}
						<Route exact path="/setting" element={<SettingPage/>} />
					</Routes>
				</div>
			</div>
			<PasswordPage visible={show} onClose={() => setShow(false)} />
			<ThemeModal visible={themeShow} onClose={refreshTheme} />
		</FullScreen>
	)
}

class App extends React.PureComponent {

	render() {
		return (
			<Provider store={store}>
				<div className="app">
					<Router>
						<AppComp />
					</Router>
				</div>
			</Provider>
		);
	}
}

export default App;
