import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./index.less";

const Tips = () => {
  return (
    <div className={styles["sw-back-top"]}>
      <div className={styles.tips}>
        <ExclamationCircleOutlined />
        <p>告警</p>
      </div>
    </div>
  );
};

export default Tips;
