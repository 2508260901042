import React from "react";
import ReactDOM from "react-dom";
import { Modal, notification } from "antd";
import store from "../redux";
import ReconnectingWebSocket from "./websockets";
import AlarmWav from "../../assets/voice/alarm.wav";
import AlarmMp3 from "../../assets/voice/alarm.mp3";
import AlarmOgg from "../../assets/voice/alarm.ogg";
import AlarmNotice from "./notice";
import AlarmTips from "./tips";
const socketUrl = `${window.location.protocol == 'http:' ? 'ws' : 'wss'}://${window.location.hostname}/saas-api/alarm/ws`;
// const socketUrl = `${window.location.protocol == 'http:' ? 'ws' : 'wss'}://microvpp.swdnkj.com/saas-api/alarm/ws`;

export default {
  socket: null,
  showNotice: true,
  tipsAlarm: [],
  tipsVNode: null,
  audioNode: null,
  init(userId, companyId) {
    if (("WebSocket" in window) && userId) {
      this.socket = new ReconnectingWebSocket(`${socketUrl}/${companyId}/${userId}`);
      // this.socket = new ReconnectingWebSocket("ws://dsm.swdnkj.com/wschat/1/273581");
      this.socket.timoutInterval = 3000;
      // 连接发生错误时回调
      this.socket.onerror = () => {
        console.error("WebSocket连接错误");
      };
      // 连接成功时建立回调
      this.socket.onopen = (e) => {
        console.info("WebSocket连接成功建立");
      };
      // 连接关闭的回调
      this.socket.onclose = () => {
        // 关闭时清除告警缓存
        // store.commit("clearTakeBellAlarm");
        // store.commit("setInitBellAlarm", []);
        store.dispatch({ type: "clearTakeBellAlarm" });
        store.dispatch({ type: "setInitBellAlarm", initBellAlarm: [] });
      };
      // 接收到消息的回调
      this.socket.onmessage = (e) => {
        console.log('告警信息', e)
        const dataMsg = JSON.parse(e.data || '{}');
        console.log('告警信息2', dataMsg)
        this.tipsAlarm.push(dataMsg);
        store.dispatch({ type: "insertBellAlarm", dataMsg });
        if (this.showNotice && this.tipsAlarm.length) {
          console.log('开启', this.tipsAlarm)
          this.show();
          this.playAudio();
        }
        // if (dataMsg.full) {
        //   store.dispatch({ type: "setInitBellAlarm", initBellAlarm: dataMsg.msg });
        // } else {
        //   this.tipsAlarm = [];
        //   // idu对应ASCII码 68-D 73-I 85-U
        //   dataMsg.msg.forEach((entry) => {
        //     if (entry.idu === 73) {
        //       this.tipsAlarm.push(entry);
        //       store.dispatch({ type: "insertBellAlarm", entry });
        //     } else if (entry.idu === 85) {
        //       store.dispatch({ type: "updateBellAlarm", initBellAlarm: entry });
        //     } else if (entry.idu === 68) {
        //       store.dispatch({ type: "deleteBellAlarm", initBellAlarm: entry });
        //     }
        //   });
        //   if (this.showNotice && this.tipsAlarm.length) {
        //     this.show();
        //     this.playAudio();
        //   }
        // }
      };
    }
  },
  show() {
    notification.destroy();
    notification.warning({
      duration: 0,
      message: "实时告警提醒",
      placement: "bottomRight",
      description: <AlarmNotice data={this.tipsAlarm} Ignore={() => notification.destroy()} />,
      onClose: () => {
        Modal.confirm({
          title: "关闭实时告警弹出",
          content: "是否选择不再弹出实时告警？",
          okText: "关闭弹出",
          cancelText: "取消",
          onOk: () => {
            notification.destroy();
            this.showNotice = false;
            store.dispatch({ type: "setShowAlarmNotice", showAlarmNotice: false });
            this.openTips();
          },
          onCancel: () => {
            if (this.tipsAlarm.length) this.show();
          },
        });
      },
    });
  },
  // 非异步方式 2021/4/5
  createAudio() {
    console.log('开始创建',this.audioNode)
    if (!this.audioNode) {
      const audioEl = document.createElement("div");
      audioEl.className = "sw-audio";
      audioEl.style.display = "none";
      audioEl.innerHTML = "<audio>" +
        `<source src="${AlarmMp3}" muted="muted" type="audio/mp3"/>` +
        `<source src="${AlarmWav}" muted="muted" type="audio/wav"/>` +
        `<source src="${AlarmOgg}" muted="muted" type="audio/ogg"/>` +
        "</audio>";

      document.body.appendChild(audioEl);
      this.audioNode = audioEl.querySelector("audio");
      console.log('创建完成')
    }
  },
  playAudio() {
    if (this.audioNode) {
      // console.log('语音',this.audioNode)
      // this.audioNode.play();
    }
  },
  // TTS实现语音播报，有兼容性问题
  speakTTS() {
    const speech = new SpeechSynthesisUtterance();
    speech.voiceURI = "native";
    speech.volume = 1; // 0 to 1
    speech.rate = 1; // 0.1 to 10
    speech.pitch = 2; // 0 to 2
    speech.lang = "zh-CN";
    speech.text = "实时告警提醒，当前有新告警信息，请查看！";
    speechSynthesis.speak(speech);
  },
  openTips() {
    const testNode = document.createElement("div");
    testNode.style.position = "absolute";
    testNode.style.bottom = "14px";
    testNode.style.right = "8px";
    testNode.id = "tip";
    ReactDOM.render(<AlarmTips onClick={this.removeTips} />, testNode);
    this.tipsVNode = testNode;
    testNode.addEventListener("click", () => {
      this.removeTips();
    });
    document.body.appendChild(testNode);
  },
  removeTips() {
    ReactDOM.unmountComponentAtNode(document.getElementById("tip"));
    document.body.removeChild(document.getElementById("tip"));
    this.tipsVNode = null;
    if (this.tipsAlarm.length) this.show();
    this.showNotice = true;
    store.dispatch({ type: "setShowAlarmNotice", showAlarmNotice: true });
  },
  close() {
    notification.destroy();
    this.socket && this.socket.close();
    if (this.tipsVNode) {
      document.body.removeChild(this.tipsVNode.$el);
      this.tipsVNode.$destroy();
    }
  },
};

